<template>
  <div>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("问题产品") }}</div>
      </div>
      <!--      问题产品状态切换-->
      <div class="tabsContainer flex" style="margin: 26px 26px 0 26px">
        <el-tabs v-model="form.status" type="card" @tab-click="form.page=1;getData()">
          <el-tab-pane :label="waitForReplyText" name="待回复"></el-tab-pane>
          <el-tab-pane :label="followUpProcedureText" name="待后续处理"></el-tab-pane>
          <el-tab-pane :label="processedText" name="已完成"></el-tab-pane>
        </el-tabs>
        <img src="../../../../assets/icon/sort.png" alt="" @click="updateGoodsSort" class="sortIcon">
        <div v-show="form.status==='待回复'&&newList.length>0" class="allCheckedContainer" @click="allChoice">
          <input v-model="checked" style="cursor: pointer" type="checkbox" @change.stop="allChoice"/>
          <div class="quanXuan">{{ $fanyi("全选") }}</div>
        </div>
        <div v-show="form.status==='待回复'&&newList.length>0" class="showBtn"
             @click="showHandlingSuggestionDialogVisible">
          {{ $fanyi("问题产品回复") }}
        </div>
        <div class="tabsRightContainer"></div>
      </div>
      <!--      问题产品明细和问题产品回复-->
      <div>
        <div v-for="(cItem,cIndex) in newList" :key="cIndex">
          <div class="flexAndCenter orderScreenContainer">
            <router-link target="_blank" class="fontSize12 fontWeightBold cursorPointer"
                         :to="{path:'/OrderDetails',query: {order_sn: cItem.order_sn}}">
              {{ $fanyi("注文") }}NO：{{ cItem.order_sn }} {{
                $fanyi("问题商品明细")
              }}
            </router-link>
          </div>
          <!-- 订单列表 -->
          <div class="issueOrderDetailTable">
            <ul class="flexAndCenter issueOrderDetailTableHeader">
              <li class="inputBox">{{ $fanyi("选择") }}</li>
              <li class="inputBox">{{ $fanyi("番号") }}</li>
              <li class="mirror">{{ $fanyi("写真") }}</li>
              <li class="goods">{{ $fanyi("商品属性") }}</li>
              <li :class="form.status !== '已完成' ?'dataAtTheTimeOfEntry':'resultOfHandling'">
                {{ form.status !== '已完成' ? $fanyi("入金时数据") : '変動内容' }}
              </li>
              <li class="issueNumber">{{ $fanyi("问题数") }}</li>
              <li class="issueDetail">{{ $fanyi("问题详细") }}</li>
              <li class="issueMirror">{{ $fanyi("问题写真") }}</li>
              <li class="operation">{{ form.status !== '待回复' ? $fanyi("处理意见") : $fanyi("操作") }}</li>
            </ul>
            <div class="goodsBox">
              <ul v-for="(item,itemIndex) in cItem.list" :key="item.id" class="goodsConUl">
                <div class="flexAndCenter">
                  <li class="inputBox flexAndCenter" style="justify-content: center">
                    <!--                    選択-->
                    <input type="checkbox" style="margin-left: 0" v-model="item.checked"/>
                  </li>
                  <!--                    番号-->
                  <li class="inputBox">{{ item.sorting }}</li>
                  <!--                    写真-->
                  <li class="mirror" style="flex-direction: column">
                    <el-popover placement="right" trigger="hover">
                      <img :src="item.pic"
                           style="width: 300px; height: 300px"
                      />
                      <img :src="item.pic"
                           slot="reference"
                           @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"
                           style="width: 60px;height: 60px;cursor: pointer"
                      />
                    </el-popover>
                    <el-popover placement="bottom" trigger="hover">
                      <div>{{ $fanyi("手续费") }}：{{
                          $fun.returnServiceRate(item.service_rate)
                        }}%
                      </div>
                      <div slot="reference">{{ item.from_platform }}</div>
                    </el-popover>
                  </li>
                  <!--                    商品詳細-->
                  <li class="goods">
                    <el-popover trigger="hover">
                      <div class="showDetailCon u-line">
                        <p v-for="(bitem, bindex) in item.detail"
                           :key="bitem.key + bindex"
                           :title="bitem.key + ':' + bitem.value"
                           class="goodsDetailOpt ispay">
                          {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                        </p>
                      </div>
                      <div class="showDetailCon u-line" slot="reference">
                        <div class="flex" style="justify-content: center;flex-direction: column">
                          <p v-for="(bitem, bindex) in item.detail"
                             :key="bitem.key + bindex"
                             :title="bitem.key + ':' + bitem.value"
                             class="goodsDetailOpt ispay">
                            {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                          </p>
                        </div>
                      </div>
                    </el-popover>
                  </li>
                  <!--                    入金时数据-->
                  <li :class="form.status !== '已完成' ?'dataAtTheTimeOfEntry':'resultOfHandling'">
                    <!--                    数量：123456 →   -->
                    <div class="newRemarkData" v-if="form.status !== '已完成'">
                      <div>{{ $fanyi("数量") }}：{{ item.offer_num_bak }}</div>
                      <div>{{ $fanyi("单价") }}：{{ item.offer_price_bak }}</div>
                      <div>{{ $fanyi("运费") }}：{{ item.offer_freight_bak }}</div>
                      <div @click="item.showStatus=!item.showStatus">{{ $fanyi("详细") }}</div>
                    </div>
                    <div class="newRemarkData" v-else>
                      <div class="flexAndCenter">{{ $fanyi("数量") }}：{{ item.offer_num_bak }}
                        <span v-if="item.offer_num_bak!=item.now_num"> →<span
                            :style="item.offer_num_bak>item.now_num?'color:#50C136':'color:#B4272B'">{{
                            item.now_num
                          }}</span></span>
                      </div>
                      <div class="flexAndCenter">{{ $fanyi("单价") }}：{{ item.offer_price_bak }}
                        <span v-if="item.offer_price_bak!=item.now_price"> →<span
                            :style="item.offer_price_bak>item.now_price?'color:#50C136':'color:#B4272B'">{{
                            item.now_price
                          }}</span></span>
                      </div>
                      <div class="flexAndCenter">{{ $fanyi("运费") }}：{{ item.offer_freight_bak }}
                        <span v-if="item.offer_freight_bak!=item.now_freight" class="flex">
                          →<span :style="item.offer_freight_bak>item.now_freight?'color:#50C136':'color:#B4272B'">{{
                            item.now_freight
                          }}</span></span>
                      </div>
                      <div class="detailsText" v-if="item.priceUpdateStatus===true"
                           @click="item.showStatus=!item.showStatus">{{ $fanyi("详细") }}
                      </div>
                    </div>
                  </li>
                  <!--                  问题数-->
                  <li class="issueNumber backgroundFAF2F2">{{ item.problem_num }}</li>
                  <!--                  问题详细-->
                  <li class="issueDetail backgroundFAF2F2">
                    <el-popover placement="top" trigger="hover" v-if="item.description_translate!==''">
                      <div style="width: 400px">{{ item.description_translate }}</div>
                      <el-input
                          resize="none"
                          v-model="item.description_translate"
                          disabled
                          slot="reference"
                          type="textarea">
                      </el-input>
                    </el-popover>
                  </li>
                  <!--                  问题写真-->
                  <li class="issueMirror flexAndCenter backgroundFAF2F2">
                    <div class="flexAndCenter" style="flex-wrap: wrap;margin-top:6px;margin-left:6px">
                      <div v-for="(picItem,picIndex) in item.problem_goods_image" :key="picIndex">
                        <el-popover placement="right" trigger="hover">
                          <img :src="picItem.img"
                               style="width: 300px; height: 300px"
                          />
                          <div slot="reference" class="problemGoodsImageItem">
                            <div class="picIndex">{{ picIndex + 1 }}</div>
                            <img slot="reference" :class="picItem.class"
                                 :src="picItem.img"
                                 class="cursorPointer"
                                 style="width:50px;height:50px;margin-left: 8px;margin-bottom:8px"
                                 @click="imgFullscreen('.' + picItem.class)">
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </li>
                  <!--                  处理意见-->
                  <li class="operation">
                    <el-popover placement="top" trigger="hover" v-if="form.status!=='待回复'">
                      <div style="width: 400px">{{ item.client_deal }}</div>
                      <el-input
                          resize="none"
                          disabled
                          slot="reference"
                          v-if="item.client_deal!==''"
                          v-model="item.client_deal"
                          @blur="updateClientDeal(item)"
                          type="textarea">
                      </el-input>
                    </el-popover>
                    <div v-else class="newBtn" @click="showNewHandlingSuggestionDialogVisible(item)">{{
                        $fanyi("处理")
                      }}
                    </div>
                  </li>
                </div>
                <div v-show="item.showStatus===true">
                  <div class="ordersListHeader">
                    <div class="goodsNo">{{ $fanyi("番号") }}</div>
                    <div class="goodsImage">{{ $fanyi("图片") }}</div>
                    <div :class="form.status !== '已完成' ? 'newGoodsAttribute' : 'goodsAttribute'">
                      {{ $fanyi("商品属性") }}
                    </div>
                    <div class="newDataAtTheTimeOfEntry" style="width: 150px">{{ $fanyi("入金时数据") }}</div>
                    <div class="newDataAtTheTimeOfEntry" v-show="form.status==='已完成'">現在</div>
                    <div class="option" v-show="form.status==='已完成'">入金时option</div>
                    <div class="option" v-show="form.status==='已完成'">当前option</div>
                    <div class="priceAdjustment" v-show="form.status==='已完成'">{{ $fanyi("金额调整") }}</div>
                    <div v-show="form.status!=='已完成'" class="amountOption">オプション</div>
                    <div v-show="form.status!=='已完成'" class="tag">{{ $fanyi("标签") }}</div>
                    <div v-show="form.status!=='已完成'" class="goodsRemark">{{ $fanyi("备注") }}</div>
                  </div>
                  <div>
                    <div class="ordersListFooter" v-for="(goodsItem,goodsIndex) in item.goodsDetail" :key="goodsIndex">
                      <div class="goodsNo">{{ goodsIndex + 1 }}</div>
                      <div class="goodsImage">
                        <el-popover placement="right" trigger="hover">
                          <el-image :src="goodsItem.pic"
                                    style="width: 300px; height: 300px"
                          />
                          <el-image :src="goodsItem.pic"
                                    slot="reference"
                                    @click="$fun.toCommodityDetails(goodsItem.goods_id,goodsItem.from_platform)"
                                    style="width: 60px;height: 60px"
                          />
                        </el-popover>
                      </div>
                      <div :class="form.status !== '已完成' ? 'newGoodsAttribute' : 'goodsAttribute'">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.detail!=null">
                          <div>
                            <div v-for="(detailItem,detailIndex) in goodsItem.detail" :key="detailIndex">
                              {{ detailItem.key }}：{{ detailItem.value }}
                            </div>
                          </div>
                          <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                            <div class="detailListContainer">
                              <div class="u-line" v-for="(detailItem,detailIndex) in goodsItem.detail"
                                   :key="detailIndex">
                                {{ detailItem.key }}：{{ detailItem.value }}
                              </div>
                            </div>
                            <div v-if="goodsItem.detail.length>3">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div class="newDataAtTheTimeOfEntry" style="width: 150px">
                        <div class="fontSize14">
                          <div>{{ $fanyi('数量') }}：{{ goodsItem.offer_num_bak }}</div>
                          <div>{{ $fanyi('单价') }}：{{ goodsItem.offer_price_bak }}</div>
                          <div>{{ $fanyi('运费') }}：{{ goodsItem.offer_freight_bak }}</div>
                        </div>
                      </div>
                      <div class="newDataAtTheTimeOfEntry" v-show="form.status==='已完成'">
                        <div class="fontSize14">
                          <div>{{ $fanyi('数量') }}：{{ goodsItem.now_num }}</div>
                          <div>{{ $fanyi('单价') }}：{{ goodsItem.now_price }}</div>
                          <div>{{ $fanyi('运费') }}：{{ goodsItem.now_freight }}</div>
                        </div>
                      </div>
                      <div class="option" v-show="form.status==='已完成'">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.option!=null">
                          <div>
                            <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                            </div>
                          </div>
                          <div class="goodsAttributeDetailsContainer cursorPointer" slot="reference">
                            <div class="optionListContainer">
                              <div class="u-line" v-for="(optionItem,optionIndex) in goodsItem.option"
                                   :key="optionIndex">
                                {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                              </div>
                            </div>
                            <div v-if="goodsItem.option.length>3">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div class="option" v-show="form.status==='已完成'">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.option!=null">
                          <div>
                            <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                            </div>
                          </div>
                          <div class="goodsAttributeDetailsContainer cursorPointer" slot="reference">
                            <div class="optionListContainer">
                              <div class="u-line" v-for="(optionItem,optionIndex) in goodsItem.option"
                                   :key="optionIndex">
                                {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                              </div>
                            </div>
                            <div v-if="goodsItem.option.length>3">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div class="priceAdjustment" v-show="form.status==='已完成'">
                        <div>{{ goodsItem.client_remark }}</div>
                      </div>
                      <div v-show="form.status!=='已完成'" class="amountOption">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.option!=null">
                          <div>
                            <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                            </div>
                          </div>
                          <div class="goodsAttributeDetailsContainer cursorPointer" slot="reference">
                            <div class="optionListContainer">
                              <div class="u-line" v-for="(optionItem,optionIndex) in goodsItem.option"
                                   :key="optionIndex">
                                {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                              </div>
                            </div>
                            <div v-if="goodsItem.option.length>3">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div v-show="form.status!=='已完成'" class="tag">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.order_detail_tag!=undefined">
                          <div>
                            <div v-for="(tagItem,tagIndex) in goodsItem.order_detail_tag" :key="tagIndex">
                              <div>{{ $fanyi('类别') }}：{{ tagItem.type_translate }}</div>
                              <div>{{ $fanyi('标签号') }}：{{ tagItem.no }}</div>
                              <div>{{ $fanyi('商品码') }}：{{ tagItem.goods_no }}</div>
                            </div>
                          </div>
                          <div slot="reference">
                            <div class="goodsTagDetailsContainer cursorPointer">
                              <div v-for="(tagItem,tagIndex) in goodsItem.order_detail_tag" :key="tagIndex">
                                <div class="u-line">{{ $fanyi('类别') }}：{{ tagItem.type_translate }}</div>
                                <div class="u-line">{{ $fanyi('标签号') }}：{{ tagItem.no }}</div>
                                <div class="u-line">{{ $fanyi('商品码') }}：{{ tagItem.goods_no }}</div>
                              </div>
                            </div>
                            <div v-if="goodsItem.order_detail_tag.length>1">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div v-show="form.status!=='已完成'" class="goodsRemark">{{ goodsItem.offer_client_remark }}</div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 30px 0;justify-content: center" class="flexAndCenter pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
      <!--      处理意见弹窗-->
      <div class="dialogContainer">
        <el-dialog
            width="415px"
            :title="$fanyi('处理意见')"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :visible.sync="handlingSuggestionDialogVisible">
          <div style="margin-top: 26px" class="radioListContainer">
            <el-radio-group v-model="radioValue" @change="opinionValue=''">
              <el-radio :label="1">{{ $fanyi("接受") }}</el-radio>
              <el-radio :label="2">{{ $fanyi("不良品换货") }}</el-radio>
              <el-radio :label="3">{{ $fanyi("退货/取消购买") }}</el-radio>
              <el-radio :label="4">{{ $fanyi("废弃") }}</el-radio>
              <el-radio :label="5">{{ $fanyi("其他") }}</el-radio>
            </el-radio-group>
          </div>
          <el-input v-show="radioValue===5" v-model="opinionValue" resize="none" type="textarea"
                    style="margin-top: -10px"
                    :placeholder="$fanyi('请输入您的处理意见，如果您已选中多番，选中的各番将给出一样的处理意见。')"/>
          <div class="btnList flexAndCenter">
            <div @click="handlingSuggestionDialogVisible=false">{{ $fanyi("取消") }}</div>
            <div @click="submitOrderHandlingSuggestion">{{ $fanyi("保存") }}</div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        status: '待回复',
        order_sn: '',
        order_by: 'desc',
        page: 1,
        pageSize: 10,
        start_time: '',
        end_time: ''
      },
      radioValue: 1,
      opinionValue: '',
      total: 0,
      newList: [],
      fromPlatformList: ['1688', 'taobao', 'tmall'],
      defectiveProductIdList: '',
      orderServiceRateList: [],
      handlingSuggestionDialogVisible: false,
      followUpProcedureText: this.$fanyi('待回复') + '（0）',
      waitForReplyText: this.$fanyi('待后续处理') + '（0）',
      processedText: this.$fanyi('已处理'),
      checked: false,
    };
  },
  created() {
    this.$api.orderGetServiceRate({from_platform: this.fromPlatformList}).then((res) => {
      this.orderServiceRateList = res.data;
    });
    this.getData();
    this.getProblemGoodsOrderNum();
  },
  methods: {
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData()
    },
    //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData()
    },
    //全选事件
    allChoice() {
      this.newList.forEach((item) => {
        item.list.forEach((items) => {
          items.checked = this.checked
        })
      })
    },
    updateGoodsSort() {
      this.form.order_by === 'asc' ? this.form.order_by = 'desc' : this.form.order_by = 'asc';
      this.getData();
    },
    //获取各个状态下的数量
    getProblemGoodsOrderNum() {
      this.$api.problemGoodsOrderNum().then((res) => {
        res.data.forEach((item) => {
          switch (item.name) {
            case '待回复':
              this.waitForReplyText = this.$fanyi('待后续处理') + `（${item.count}）`;
              break;
            case '待后续处理':
              this.followUpProcedureText = this.$fanyi('待回复') + `（${item.count}）`;
              break;
          }
        })
      });
    },
    // 获取问题产品数据
    getData() {
      this.$api.problemGoodsOrderList(this.form).then((res) => {
        if (res.code != 0) return;
        let count = 1;
        res.data.data.forEach((item) => {
          item.checked = false;
          item.list.forEach((items) => {
            items.optionCheckedNum = 0;
            items.checked = false;
            items.service_rate = this.$fun.getServiceRate(this.orderServiceRateList, items.from_platform);
            items.showStatus = false;
            items.priceUpdateStatus = (items.offer_num_bak != items.now_num) || (items.offer_price_bak != items.now_price) || (items.offer_freight_bak != items.now_freight);
            items.goodsDetail = [];
            let arr = [];
            items.problem_goods_image.forEach((imageItem) => {
              arr.push({
                img: imageItem,
                class: 'problem_goods_image' + count
              })
              count++
            })
            items.problem_goods_image = arr;
            items.goodsDetail.push(items);
          })
        })
        this.total = res.data.total;
        this.newList = res.data.data;
      });
    },
    //更新问题产品处理意见
    updateClientDeal(item) {
      if (item.client_deal !== '') {
        this.$api.problemGoodsClientDeal({
          problem_goods_id: item.problem_goods_id,
          client_deal: item.client_deal
        }).then((res) => {
          if (res.code != 0) return;
          this.$message.success(this.$fanyi(res.msg));
          this.getData();
          this.getProblemGoodsOrderNum();
        });
      }
    },
    //是否展示处理意见弹窗弹窗
    showHandlingSuggestionDialogVisible() {
      this.defectiveProductIdList = '';
      this.newList.forEach((item) => {
        item.list.forEach((items) => {
          if (items.checked === true) {
            this.defectiveProductIdList += items.problem_goods_id + ','
          }
        })
      })
      this.defectiveProductIdList = this.defectiveProductIdList.substr(0, this.defectiveProductIdList.length - 1);
      if (this.defectiveProductIdList === '') {
        this.$message.error(this.$fanyi("请至少选择一个问题产品"))
        return
      }
      this.handlingSuggestionDialogVisible = true;
    },
    showNewHandlingSuggestionDialogVisible(val) {
      this.defectiveProductIdList = val.problem_goods_id;
      this.handlingSuggestionDialogVisible = true;
    },
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    },
    //提交问题产品处理意见
    submitOrderHandlingSuggestion() {
      //处理意见是其他并且备注没填写
      if (this.radioValue === 5 && this.opinionValue === '') {
        this.$message.error(this.$fanyi("请输入处理意见"))
        return
      }
      //处理意见不是其他则添加快捷处理文字加客户选择的处理方式
      if (this.radioValue !== 5) {
        switch (this.radioValue) {
          case 1:
            this.opinionValue = this.$fanyi('快捷处理') + this.$fanyi('接受');
            break;
          case 2:
            this.opinionValue = this.$fanyi('快捷处理') + this.$fanyi('不良品换货');
            break;
          case 3:
            this.opinionValue = this.$fanyi('快捷处理') + this.$fanyi('退货/取消购买');
            break;
          case 4:
            this.opinionValue = this.$fanyi('快捷处理') + this.$fanyi('废弃');
            break;
        }
      }
      this.$api.problemGoodsClientDeal({
        problem_goods_id: this.defectiveProductIdList,
        client_deal: this.opinionValue
      }).then((res) => {
        if (res.code != 0) return;
        this.handlingSuggestionDialogVisible = false;
        this.$message.success(this.$fanyi(res.msg))
        this.getData();
        this.getProblemGoodsOrderNum();
      });
    }
  }
  ,
}
;
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";


/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .tabsContainer {
    position: relative;
    overflow: hidden;

    .sortIcon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-left: 20px;
      position: absolute;
      top: 10px;
      left: 330px;
    }

    .allCheckedContainer {
      display: flex;
      align-items: center;
      position: absolute;
      top: 10px;
      right: 165px;
      cursor: pointer;

      div {
        font-size: 14px;
        color: #333333;
        margin-left: 10px;

      }
    }

    .showBtn {
      height: 30px;
      padding: 0 10px;
      background: #B4272B;
      line-height: 30px;
      text-align: center;
      border-radius: 6px;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 0;
    }

    .tabsRightContainer {
      flex: 1;
      height: 40px;
      border-bottom: 1px solid #B4272B;
      width: 100%;
    }

    /deep/ .el-tabs__header {
      border-bottom: none;
      margin: 0;
    }

    /deep/ .is-active {
      border-top: 1px solid #B4272B !important;
      border-right: 1px solid #B4272B !important;
      border-left: 1px solid #B4272B !important;
      border-bottom: 1px solid transparent !important;
    }

    /deep/ .el-tabs__item {
      border-radius: 10px 10px 0 0;
      border-top: 1px solid #DFDFDF;
      border-left: 1px solid #DFDFDF;
      border-right: 1px solid #DFDFDF;
      border-bottom: 1px solid #B4272B;
    }

    /deep/ .el-tabs__nav {
      border: none;
    }
  }

  .issueOrderDetailTableHeader {
    background: #F0F0F0;
    height: 56px;

    li {
      line-height: 56px;
      text-align: center;
      font-size: 14px;
      color: #333;
      background: #F0F0F0;
    }

    li:first-child {
      border-radius: 10px 0 0 0;
      overflow: hidden;
    }

    li:last-child {
      border-top-right-radius: 10px !important;
    }
  }

  .inputBox {
    width: 50px;
    //min-width: 50px;
    //flex: 1 0 50px;
    //flex: 16
  }

  .mirror {
    width: 88px;
    //min-width: 88px;
    //flex: 1 0 88px;
    //flex: 20
  }

  .goods {
    width: 160px;
    //min-width: 160px;
    //flex: 2 0 160px;
    //flex: 40
  }

  .newGoods {
    //width: 130px;
    //min-width: 130px;
    flex: 40;

    .optionHiddenContainer {
      height: 60px;
      overflow: hidden;
    }
  }

  .dataAtTheTimeOfEntry {
    width: 115px;
    //min-width: 100px;
    //flex: 30;

    .newRemarkData {
      div {
        width: 100%;
        text-align: left;
      }

      div:last-child {
        text-decoration: underline;
        font-size: 14px;
        color: #B4272B;
        cursor: pointer;
      }
    }
  }

  .resultOfHandling {
    width: 180px;

    .newRemarkData {
      width: 100%;

      .flexAndCenter {
        width: 100%;
        text-align: left;
      }

      .detailsText {
        text-decoration: underline;
        font-size: 14px;
        color: #B4272B;
        cursor: pointer;
        text-align: left;
      }
    }
  }

  .remark {
    //width: 120px;
    //min-width: 120px;
    flex: 30;

    /deep/ .el-textarea__inner {
      width: 100px;
      height: 100px;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
    }
  }

  .newRemark {
    flex: 30;

    /deep/ .el-textarea__inner {
      width: 80px;
      height: 80px;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
    }
  }

  .issueMirror {
    width: 140px;

    .problemGoodsImageItem {
      position: relative;

      .picIndex {
        width: 14px;
        height: 14px;
        background: #0072FD;
        border-radius: 3px;
        line-height: 14px;
        text-align: center;
        font-size: 12px;
        color: #FFFFFF;
        position: absolute;
        top: 2px;
        left: 10px;
        z-index: 1;
      }
    }
  }

  .operation {
    width: 186px;

    /deep/ .el-textarea__inner {
      width: 162px;
      height: 100px;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
      color: #000;
    }

    .newBtn {
      width: 60px;
      height: 30px;
      background: #B4272B;
      border-radius: 6px;
      line-height: 30px;
      text-align: center;
      color: #FEFEFE;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .issueNumber {
    //flex: 25;
    width: 80px;
  }

  .issueDetail {
    width: 248px;

    /deep/ .el-textarea__inner {
      width: 230px;
      height: 100px;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
      color: #000;
    }
  }

  .shopDetailContainer {
    height: 60px;
    min-width: 60px;
    background-color: #fff;
  }

  .goodsConUl {
    //height: 120px;
    background: #F9F9F9;
    width: 100%;

    li {
      text-align: center;
      font-size: 14px;
      color: #333;
      background: #F9F9F9;
      height: 122px;
      display: flex;
      align-items: center;
      justify-content: center;
      //line-height: 120px;
    }

    .showDetailCon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 98px;
      overflow: hidden;

      p {
        width: 160px;
        padding-left: 21px;
        text-align: left;
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .ordersListHeader {
      display: flex;
      background: #D7D7D7;

      div {
        color: #333333;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        height: 42px;
        line-height: 42px;
      }
    }

    .goodsNo {
      width: 60px;
    }

    .goodsImage {
      width: 88px;
    }

    .goodsAttribute {
      width: 180px;
    }

    .newGoodsAttribute {
      width: 200px;

      .goodsAttributeDetailsContainer {
        .detailListContainer {
          width: 139px;
          height: 56px;
          overflow: hidden;
        }

        div {
          text-align: left;
          color: #333333;
          font-size: 14px;
          width: 139px;
        }
      }
    }

    .amountOption {
      width: 200px;

      .goodsAttributeDetailsContainer {
        .optionListContainer {
          width: 152px;
          height: 56px;
          overflow: hidden;
        }

        div {
          text-align: left;
          color: #333333;
          font-size: 14px;
          width: 152px;
        }
      }
    }

    .tag {
      width: 220px;

      .goodsAttributeDetailsContainer {
        .optionListContainer {
          width: 172px;
          height: 56px;
          overflow: hidden;
        }

        div {
          text-align: left;
          color: #333333;
          font-size: 14px;
          width: 172px;
        }
      }
    }

    .newDataAtTheTimeOfEntry {
      width: 140px;
    }

    .backgroundFAF2F2 {
      background: #FAF2F2 !important;
    }

    .goodsRemark {
      width: 184px;
    }

    .option {
      width: 167px;
    }

    .goodsAttributeDetailsContainer {
      .optionListContainer, .detailListContainer {
        width: 119px;
        height: 56px;
        overflow: hidden;
      }

      div {
        text-align: left;
        color: #333333;
        font-size: 14px;
        width: 119px;
      }
    }

    .priceAdjustment {
      width: 160px;
    }

    .ordersListFooter {
      display: flex;
      background: #FFF8EE;
      height: 120px;

      .ordersNumber, .goodsNo, .goodsImage, .goodsAttribute, .newDataAtTheTimeOfEntry, tag, .quantityShipped, .option, .ordersNumber, .priceAdjustment, .newGoodsAttribute, .amountOption {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .orderScreenContainer {
    padding-bottom: 15px;
    margin: 15px 26px 0;
    border-bottom: 1px solid #DFDFDF;
    justify-content: space-between;

  }

  .issueOrderDetailTable {
    margin-left: 26px;
    margin-top: 20px;
    overflow-y: scroll;
  }

  .dialogContainer {
    /deep/ .el-dialog {
      padding: 30px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    /deep/ .el-dialog__header {
      padding: 0;
      display: flex;
      justify-content: center;

      .el-dialog__title {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        padding-bottom: 7px;
        border-bottom: 4px solid #B4272B;
        text-align: center;
      }

      .el-dialog__headerbtn {
        top: 30px;
        font-size: 19px;

        .el-dialog__close {
          color: #B4272b;
        }
      }
    }

    /deep/ .el-dialog__body {
      padding: 0;
    }

    .radioListContainer {
      /deep/ .el-radio {
        margin-right: 0;
        width: 105px;
        margin-bottom: 25px;
      }
    }

    /deep/ .el-textarea__inner {
      height: 80px;
      margin-bottom: 30px;
    }

    .btnList {
      justify-content: center;

      div {
        width: 120px;
        height: 34px;
        background: #B4272B;
        border-radius: 8px;
        line-height: 34px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        font-size: 14px;

        border: 1px solid #B4272B;
      }

      div:first-child {
        background: #fff;
        color: #B4272B;
        border: 1px solid #B4272B;
        margin-right: 30px;
      }
    }
  }

  .dataBody {
    margin: 20px 26px 0;

    .oNO {
      width: 50px;
    }

    .orderNumber {
      width: 200px;

      button {
        text-decoration: underline;
        color: #B4272B;
      }
    }

    .datePresentation {
      width: 170px;
    }

    .upLoad {
      width: 90px;
    }

    .orderStatus {
      width: 140px;
    }

    .operation {
      width: 112px;
    }

    .head {
      justify-content: center;
      background: #F0F0F0;
      font-size: 14px;
      color: #333;
      height: 56px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
    }

    .obody {
      text-align: center;
      height: 70px;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;
    }
  }

  .pagination {
    /deep/ .el-pagination .el-select .el-input {
      width: 110px;
    }

    /deep/ .el-icon-arrow-right {
      padding-left: 0;
    }
  }
}
</style>
